import styled from 'styled-components'

export const DescontoTotem = styled.div`
  background-color: #c4ddf2;
  width: 100%;
  min-height: 102vh;
  padding-bottom: 30px;
  margin-top: -80px;
  background-position: center;
  background-size: cover;

  .title {
    padding-top: 180px;
    text-align: center;
    @media (max-width: 768px){
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 120px;
      img{
        width: 30%;
      }
    }
    h1 {
      color: #001e62;
      font-style: italic;
      font-weight: 700;
      font-size: 46px;
      line-height: 32px;
      padding-top: 30px;
      font-family: 'ASICSFont3.0-Bold', sans-serif;
    }
  }

  .desconto{


    h1 {
      color: #001e62;
      font-style: italic;
      font-weight: 700;
      font-size: 46px;
      line-height: 32px;
      padding-top: 30px;
      font-family: 'ASICSFont3.0-Bold', sans-serif;
    }
    text-align: center;
    padding-bottom: 50px;
    @media (max-width: 768px){
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 25px;
    }
    p{
      font-style: italic;
      font-weight: 700;
      font-size: 46px;
      line-height: 47px;
      color: #001E62;
      font-family: 'ASICSFont3.0-Bold', sans-serif;
      @media (max-width: 450px){
          font-size: 18px;
          line-height: 22px;
      }
      :nth-child(2){
          @media (max-width: 450px){
            span{
              font-size: 24px;
              line-height: 28px;
            } 
        }
      }
    }
    span{
        color: #4A5FC4;
        font-style: italic;
        font-weight: 700;
        font-size: 240px;
        line-height: 248px;
        font-family: 'ASICSFont3.0-Bold', sans-serif;
        @media (max-width: 450px){
          font-size: 98px;
          line-height: 120.469px;
        }
    }
    
    
  }

  .vendedor{
    background: #4A5FC4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    gap: 15px;

    p{
        font-weight: 700;
        font-size: 45px;
        line-height: 19px;
        color: white;
        font-family: 'ASICSFont3.0-Bold', sans-serif;
    }
    @media (max-width: 450px){
      border-radius: 131.579px;
      background: #4A5FC4;
      padding: 18px 25px;
      margin: 0px 10px;
      img{
        width: 15%;
      }
      p{
        font-size: 18px;
      }
    }
  }

  .infDesconto{
    padding: 60px 0;
    text-align: center;
    @media (max-width: 768px){
      padding: 60px 30px;
    }
    p{
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #001E62;
        text-transform: uppercase;
        font-family: 'ASICSFont3.0-Bold', sans-serif; 
    }
  }
`


